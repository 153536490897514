import.meta.glob([
    '../favicon/**',
    '../fonts/**',
    '../images/**',
]);

import './bootstrap';

const navbar = document.querySelector('header > div');
const drawer = document.querySelector('header [data-drawer-target=drawer-navigation]');

addEventListener('scroll', function(event) {
	const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0

    if (scrollTop <= 80) {
		navbar.classList.add('!bg-transparent');
	} else {
        navbar.classList.remove('!bg-transparent');
	}
})


addEventListener('load', function(event) {
	resizeFrames()
})
addEventListener('resize', function(event) {
	resizeFrames()
})

function resizeFrames() {
	const ratio = 16 / 9;
	const frames = document.querySelectorAll('iframe.iframe-resize');
	for (const frame of frames) {
		let width = frame.parentNode.parentElement.clientWidth;
		let height = frame.parentNode.parentElement.clientHeight;
		const ratioWidth = (height * ratio) + (height / 2)

		if(width < ratioWidth) {
			width = ratioWidth
		} else {
			const ratioHeight = (width / ratio)
			height = ratioHeight
		}

		frame.style.height = height + 'px';
		frame.style.width = width + 'px';
	}
}